<template>
    <div class="forecast__head">
        <div class="timestamps">
            <div class="timestamp__empty"></div>
            <div class="timestamp__entry" v-for="[date, timestampForecast] in (dataToDisplay.data || [])" :key="timestampForecast">
                <div class="timestamp__value">{{ getTime(date) }}</div>
                <img :src="getImgUrl(date, timestampForecast)" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import {getImgUrl} from "@/helpers";

export default {
    inject: ['dataToDisplay', 'selectedDate'],
    props: ['dayName'],
    methods: {
        getTime(dateIsoStr) {
            let date = new Date(dateIsoStr);
            return date.getHours().toString().padStart(2, '0') + ':00';
        },
        getImgUrl(date, timestampForecast) {
            return getImgUrl(date, timestampForecast);
        }
    }
}
</script>

<style>
.timestamp__value {
    margin-bottom: 6px;
}
.forecast__head {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    font-weight: bold;
}

.forecast__head .timestamps{
    display: flex;
    flex-direction: row;
}

.forecast__head .timestamps div {
    min-width: var(--cell-width);
    max-width: var(--cell-width);
}
.timestamp__entry {
    border-left: 2px solid #E0FBFF;
}
.timestamp__entry {
    border-top: 2px solid #E0FBFF;
    padding: 16px 0 9px 0;
}
.timestamp__entry img {
    height: 50px;
}
.timestamp__entry:last-child {
  border-top-right-radius: 10px;
  border-right: 2px solid #E0FBFF;
}
.timestamp__empty,
.timestamp__entry {
    position: relative;
}
.timestamp__empty:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 35px;
    background-color: white;
    font-size: 11px;
    top: -35px;
    left: 0;
    border-radius: 10px 10px 0 0;
}
.timestamp__entry:nth-child(even) {
    background: linear-gradient(#E0FBFF, #F7FEFF);
}

.timestamp__empty:before,
.timestamp__entry:before {
    content: '';
    position: absolute;
    top: -42px;
    background: white;
    left: 0;
    height: 40px;
    width: 100%;
}
.timestamp__entry:before {
    border-left: 2px solid white;
    border-right: 2px solid white;
}
.timestamp__entry:nth-child(2):before {
    margin-left:-2px;
}
.timestamp__entry:nth-child(2):after {
    content: 'Now';
    position: absolute;
    top: -33px;
    left: -2px;
    padding: 8px 2px 11px 1px;
    border-radius: 10px 10px 0 0;
    background-color: #E0FBFF;
    color: #009FAE;
    font-size: 11px;
    width: 100%;
}
.timestamp__empty {
    background-color: white;
    z-index: 10;
    position: sticky;
    left: 0;
    top: 0;
    border: 0
}
</style>
