<template>
    <div>
        <nav-single></nav-single>
        <div class="body-single">
            <div class="body-single__body">
                <forecast-flex :id="id" v-if="swimSpots.spots[id] && swimSpots.spots[id].weather_data"></forecast-flex>
            </div>
        </div>
    </div>
</template>

<script>
import NavSingle from '@/components/Nav/NavSingle.vue'
import ForecastFlex from '@/components/ForecastFlex'
export default {
    inject: ['swimSpots'],
    data() {
        return {
            id: this.$route.params.id * 1.0,
        };
    },
    components: {
        NavSingle,
        ForecastFlex,
    },
    mounted() {
        this.$emit('fetchForecast', this.id);
    },
}
</script>
