<template>
    <line-chart-wrapper
        header="Precipitation"
        valueProp="location.precipitation.value"
    >
        <template #icon>
            <img src="@/assets/img/precipitation.svg" alt="">
        </template>
    </line-chart-wrapper>
</template>
<script>
import LineChartWrapper from "./LineChartWrapper";
export default {
    components: {
        LineChartWrapper
    },
}
</script>
