<template>
    <div :class="reverse? 'static-scroll--reverse' : 'static-scroll'">
        <div class="static-scroll__wrapper">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        reverse: {
            type: Boolean,
        }
    },
}
</script>

<style scoped>
/** ----------- Static scroll ----------- **/
.static-scroll--reverse {
    justify-content: flex-end;
    border-color: rgb(89, 94, 110);
    border-style: solid;
    border-width: 2px 0;
}
.static-scroll {
    padding-top: 10px;
}
.static-scroll,
.static-scroll--reverse {
    position: relative;
    display: flex;
    align-items: center;
}
.static-scroll > div,
.static-scroll--reverse > div {
    position: sticky;
    bottom: -50%;
}

/** ----------- Padding hack ----------- **/
.static-scroll div { left: 11px; }
.static-scroll--reverse > div { right: 11px; }

.static-scroll--reverse .static-scroll__wrapper {
    padding: 5px 0;
}
</style>
