<template>
    <forecast-section :name="header">
        <template #icon>
            <slot name="icon"></slot>
        </template>
        <div
            v-for="[, timestampForecast] in (dataToDisplay.data || [])" :key="timestampForecast"
            class="chart__entry"
        >
            <div class="chart__body">
                {{ extractValue(timestampForecast) + (symbol || '') }}
            </div>
        </div>
    </forecast-section>
</template>
<script>
import ForecastSection from "../ForecastSection";
import {getPropByString} from "@/helpers";

export default {
    inject: ['dataToDisplay', 'selectedDate'],
    props: ['header', 'valueProp', 'symbol'],
    components: {
        ForecastSection,
    },
    methods: {
        extractValue(entry) {
            return getPropByString(entry, this.valueProp);
        }
    },
    watch: {
        dataToDisplay: {
            handler() {
                this.$forceUpdate();
            },
            deep: true,
        }
    }
}
</script>
<style>
.chart__entry {
    display: flex;
    align-items: center;
    justify-content: center;
}
.chart__entry + .chart__entry {
    border-left: 2px solid #E0FBFF;
}
</style>
