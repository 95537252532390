<template>
    <div class="forecast" :style="cssVars">
        <div class="forecast__wrapper">
            <div class="forecast__inner-wrapper" :style="{
                'width': wrapperWidth
            }">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        cellWidth: {
            type: Number,
            required: true,
        },
        dataToDisplay: {
            type: Object,
            required: true,
        }
    },
    computed: {
        cssVars() {
            return {
                '--cell-width': this.cellWidth + 'px',
            }
        },
        wrapperWidth() {
            const count = (this.dataToDisplay && this.dataToDisplay.data && this.dataToDisplay.data.size || 24) + 2;
            return count * this.cellWidth + 'px';
        }
    },
}
</script>

<style scoped>
:root {
    --cell-width: 60; /** just so php storm won't complain **/
}
.forecast {
    display: flex;
    flex-direction: column;
    max-width: 93vw;
    overflow-x: auto;
    border-radius: 10px;
    padding-top: 35px;
    background: linear-gradient(#F7FEFF, #FEFEFF);
    font-family: "DM Sans", "Open Sans", sans-serif;
    font-size: 13px;
    color: #2A2733;
}
.forecast__wrapper {
    position: relative;
}
</style>
