<template>
    <forecast-wrapper
        :dataToDisplay="dataToDisplay"
        v-if="dataToDisplay" :cellWidth="62"
    >
        <forecast-head :dayName="currentDay"></forecast-head>
        <temperature></temperature>
        <cloud></cloud>
        <wind></wind>
        <waves v-if="swimSpots.spots[id].closestStation && swimSpots.spots[id].closestStation.data"></waves>
        <preciption></preciption>
        <atmospheric-pressure></atmospheric-pressure>
    </forecast-wrapper>
</template>
<script>
import ForecastHead from './Forecast/ForecastHead';
import ForecastWrapper from './Forecast/ForecastWrapper';
import AtmosphericPressure from './Forecast/Sections/AtmosphericPressure'
import Preciption from "./Forecast/Sections/Precipitation";
import Cloud from "./Forecast/Sections/Cloud";
import Temperature from "./Forecast/Sections/AirTemperature";
import Wind from "./Forecast/Sections/Wind";
import Waves from './Forecast/Sections/Tides'
import {partition, parseDate} from "@/helpers";
import {inject, provide, reactive, ref} from "vue";

export default {
    props: ['id'],
    components: {
        Temperature,
        Cloud,
        Preciption,
        ForecastWrapper,
        ForecastHead,
        AtmosphericPressure,
        Wind,
        Waves
    },
    setup(props) {
        const swimSpots = inject('swimSpots');

        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let currentDay = ref('');
        const selectedDate = parseDate(new Date());

        if(!swimSpots.spots[props.id].weather_data.parsed) {
            swimSpots.spots[props.id].weather_data.parsed = new Map();
        }
        if(!swimSpots.spots[props.id].dailyForecast) {
            swimSpots.spots[props.id].dailyForecast = new Map();
        }
        const parsedData = swimSpots.spots[props.id].weather_data.parsed;
        let dataToDisplay = reactive({
            data: null,
        });

        provide('dataToDisplay', dataToDisplay);
        provide('selectedDate', selectedDate);
        return {
            days,
            currentDay,
            selectedDate,
            parsedData,
            swimSpots,
            dataToDisplay
        };
    },
    mounted() {
        const date = new Date();
        this.currentDay = this.days[date.getDay()];
        this.splitByTime();
        this.splitToHourly(24);
        this.dataToDisplay.data = this.swimSpots.spots[this.id].dailyForecast;
        // this.handleTidesUpdate();
    },
    methods: {
        splitByTime() {
            if(this.parsedData && this.parsedData.size) return;

            const singleSpot = this.swimSpots.spots[this.id];
            const [rangeTime, exactTime] = partition(singleSpot.weather_data.product.time, (value, index) => index%2);
            for(let time of exactTime) {
                const date = parseDate(new Date(time.from));
                let currentDayElem = this.parsedData.get(date);

                if(! currentDayElem) {
                    this.parsedData.set(date, currentDayElem = new Map());
                }

                currentDayElem.set(time.from, {
                    location: time.location,
                })
            }
            for(let time of rangeTime) {
                const date = parseDate(new Date(time.from));
                const currentDayElem = this.parsedData.get(date);

                if(! currentDayElem) { continue; }
                if(! currentDayElem.has(time.from)) continue;

                currentDayElem.get(time.from).location.precipitation = time.location.precipitation
            }
        },
        splitToHourly(hours) {
            if( this.swimSpots.spots[this.id].dailyForecast && this.swimSpots.spots[this.id].dailyForecast.size >= hours ) return;
            const parsedDataEntries = this.swimSpots.spots[this.id].weather_data.parsed.entries();
            const currentDayEntries = parsedDataEntries.next().value[1];

            const dailyForecastMap =
                this.swimSpots.spots[this.id].dailyForecast =
                    !this.swimSpots.spots[this.id].dailyForecast? new Map() : this.swimSpots.spots[this.id].dailyForecast;

            const remainingEntries = Array.from(parsedDataEntries.next().value[1]).slice(0, Math.max(0, hours - currentDayEntries.size));
            const finalEntries = [...currentDayEntries, ...remainingEntries].slice(0, hours);

            finalEntries.map(([key, value]) => dailyForecastMap.set(key, value));
            return this.swimSpots.spots[this.id].dailyForecast;
        },
        handleTidesUpdate() {
            const singleSpot = this.swimSpots.spots[this.id];
            for(let [timestamp, data] of this.dataToDisplay.data.entries()) {
                data.location.tides = singleSpot.closestStation.data.get(timestamp);
            }
        }
    },
    watch: {
        swimSpots: {
            handler(newValue) {
                const singleNewSpot = newValue.spots[this.id];
                if(! singleNewSpot.closestStation || ! singleNewSpot.closestStation.data) return;
                this.handleTidesUpdate();
            },
            deep: true,
        }
    },
}
</script>
