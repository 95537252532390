<template>
    <div class="header">
        <div class="header__head">
            <div class="logo__entry">
                <router-link to="/">
                    <img src="@/assets/img/logo.svg" alt="Swim Ireland">
                </router-link>
            </div>
            <div class="exit__entry">
                <router-link to="/">
                    <img src="@/assets/img/x-circle-fill.svg" alt="Back to Map">
                </router-link>
            </div>
        </div>
        <div class="header__body">
            <div class="nav">
                <div class="nav__head">
                    <div class="title__icon">
                        <img src="@/assets/img/swim-map-pin.png" alt="Swim Spot">
                    </div>
                    <div class="title__text">
                        <h1>{{ title }}</h1>
                        <h5>{{ coordinates }}</h5>
                    </div>
                </div>
                <div class="nav__body">
                    <ul class="navbar">
                        <li class="nav-item">
                            <router-link active-class="active" class="nav-link control" :to="liveInfoPage">
                                Live Information
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link active-class="active" class="nav-link control" :to="forecast">
                                Forecast
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link active-class="active" class="nav-link control" :to="localInfo">
                                Local Information
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link active-class="active" class="nav-link control" :to="connect">
                                Connect
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {requireSpotInfo} from "@/helpers";

export default {
    inject: [ 'swimSpots' ],
    data() {
        return {
            liveInfoPage: {
                name: 'Live',
                params: {
                    id: this.$route.params.id,
                }
            },
            forecast: {
                name: 'Forecast',
                params: {
                    id: this.$route.params.id,
                }
            },
            localInfo: {
                name: 'Info',
                params: {
                    id: this.$route.params.id,
                }
            },
            connect: {
                name: 'Connect',
                params: {
                    id: this.$route.params.id,
                }
            },
            singleSpot: this.swimSpots.spots[this.$route.params.id * 1.0],
        }
    },
    mounted() {
        if( ! this.singleSpot || ! this.singleSpot.loaded ) {
            const id = this.$route.params.id * 1.0;
            requireSpotInfo(id)
                .then((response) => {
                    this.singleSpot = response.data;
                    this.swimSpots.spots[id] = this.singleSpot;
                    this.swimSpots.spots[id].loaded = true;
                    this.swimSpots.spots = {...this.swimSpots.spots};
                })
        }
    },
    methods: {
        ucFirst(str) {
            return str && str.split(' ').map((word) => {
                return word && word.length? word[0].toUpperCase() + word.slice(1): '';
            }).join(' ');
        },
    },
    computed: {
        title() {
            const title = [this.spotName, this.cityName];
            title.map(this.ucFirst);
            return title.join(', ');
        },
        spotName() {
            return this.singleSpot?.name || '';
        },
        cityName() {
            return this.singleSpot?.city?.name || '';
        },
        coordinates() {
            const separator = ', '
            const coord = this.singleSpot?.latitude + separator + this.singleSpot?.longitude;
            return (coord.length - separator.length)? coord: '';
        }
    }
}
</script>
<style scoped>
    .header {
        --primary-gray: #2a2733;
        --primary-green: #00b0b9;
    }
    .header__head {
        padding: 17px 30px 21px 30px;
        display: flex;
        justify-content: space-between;
    }
    .logo__entry {
        padding-top: 10px;
    }
    .header__body {
        font-family: "Open Sans", sans-serif;
        padding: 0 120px 0 120px
    }
    .nav {
        display: flex;
        justify-content: space-between;
    }
    .nav__head {
        display: flex;
        text-align: left;
        padding-top: 6px;
    }
    .title__icon {
        margin-right: 21px;
    }
    .title__icon img {
        height: 56.66px;
    }
    .nav__head .title__text h1 {
        font-size: 25px;
        color: #2A2733;
        letter-spacing: -1px;
    }
    .nav__head .title__text h5 {
        color: #92909A;
        font-size: 12px;
        margin-top: 5px;
    }
    .nav__body .navbar{
        padding: 10px;
        list-style-type: none;
        background-color: #F4F4F4;
        border-radius: 10px;
    }
    .nav__body .navbar .nav-item{
        display: inline-block;
    }
    .nav__body .navbar .nav-item + .nav-item {
        margin-left: 10px;
    }
    .nav__body .navbar .nav-item .nav-link {
        padding: 15px 25px;
        display: block;
        color: black;
        border-radius: 8px;
        text-decoration: none;
        font-size: 13px;
        font-weight: 600;
    }
    .nav__body .navbar .nav-item .nav-link.active {
        background-color: #009FAE;
        color: white;
    }
    @media (min-width:320px)  {
        .header__body {
            padding: 0 30px 0 30px;
        }
        .header__body .nav{
            flex-direction: column;
        }
        .header__body .nav .nav__head {
            justify-content: center;
        }
        .header__body .nav .nav__body {
            margin-top: 32px;
        }
    }
    @media (min-width:480px)  {
        .header__head {
            padding: 30px 30px 34px 70px;
        }
    }
    @media (min-width:801px)  {
        .nav__head {
            margin-right: 1em;
        }
        .header__body {
            padding: 0 120px 0 120px;
        }
        .header__body .nav{
            flex-direction: row;
        }
        .header__body .nav .nav__body {
            margin-top: 0;
        }
    }
</style>
