<template>
    <div class="forecast__section">
        <static-scroll reverse v-if="false">
            <div class="section__head">
                <span class="section__heading">{{ name }}</span>
            </div>
        </static-scroll>
        <div class="section__chart">
            <div class="chart__icon">
                <div class="icon__wrapper">
                    <slot name="icon"></slot>
                </div>
            </div>
            <div class="chart__wrapper">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
import StaticScroll from '@/components/Forecast/StaticScroll';
export default {
    props: ['name'],
    components: {
        StaticScroll,
    }
}
</script>

<style scoped>
.forecast__section .chart__wrapper {
    border-left: 2px solid #E0FBFF;
}

.forecast__section  .section__chart .chart__icon,
.forecast__section  .section__chart .chart__wrapper{
    border-top: 2px solid #E0FBFF;
}
.forecast__section  .section__chart .chart__wrapper {
    border-right: 2px solid #E0FBFF;
}
.forecast__section:last-child .chart__wrapper {
    border-bottom: 2px solid #E0FBFF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.forecast__section .section__chart .chart__wrapper{
    display: flex;
    flex-direction: row;
}
.section__chart .chart__wrapper{
    position: relative;
    min-height: 60px;
}
.section__chart {
    display: flex;
}
</style>

<style>
.chart__entry {
    min-width: var(--cell-width);
    max-width: var(--cell-width);
}
.chart__entry:nth-child(odd) {
    background: #F7FEFF;
}
.chart__icon {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 10;
    min-width: var(--cell-width);
    max-width: var(--cell-width);
}
.chart__icon .icon__wrapper {
    max-width: 30px;
    height: 28px;
}
.chart__icon .icon__wrapper > img {
    max-height: 100%;
    min-height: 100%;
    max-width: inherit;
}
</style>
