<template>
    <line-chart-wrapper
        header="Cloud"
        valueProp="location.cloudiness.percent"
        symbol="%"
    >
        <template #icon>
            <img src="@/assets/img/cloud.svg" alt="">
        </template>
    </line-chart-wrapper>
</template>
<script>
import LineChartWrapper from "./LineChartWrapper";
export default {
    components: {
        LineChartWrapper
    },
}
</script>
