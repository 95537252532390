<template>
    <line-chart-wrapper
        class="air-temperature-wrapper"
        header="Air Temperature"
        valueProp="location.temperature.value"
        symbol="°"
    >
        <template #icon>
            <img src="@/assets/img/air-temperature.svg" alt="">
        </template>
    </line-chart-wrapper>
</template>
<script>
import LineChartWrapper from "./LineChartWrapper";
export default {
    components: {
        LineChartWrapper
    },
}
</script>
<style>
.air-temperature-wrapper {
    font-weight: bold;
}
</style>
