<template>
    <line-chart-wrapper
        header="Atmospheric Pressure"
        valueProp="location.pressure.value"
    >
        <template #icon>
            <img src="@/assets/img/atmospheric-pressure.svg" alt="">
        </template>
    </line-chart-wrapper>
</template>
<script>
import LineChartWrapper from "./LineChartWrapper";
export default {
    components: {
        LineChartWrapper
    },
}
</script>
