<template>
    <forecast-section name="Wind">
        <template #icon>
            <img src="@/assets/img/wind.svg" alt="">
        </template>
        <div
            v-for="[, timestampForecast] in (dataToDisplay.data || [])" :key="timestampForecast"
            class="chart__entry"
        >
            <div class="chart__head">
                {{ timestampForecast.location.windSpeed.mps }}
            </div>
            <div class="chart__body">
                {{ timestampForecast.location.windDirection.name }}
            </div>
        </div>
    </forecast-section>
</template>
<script>
import ForecastSection from "../ForecastSection";
export default {
    inject: ['dataToDisplay', 'selectedDate'],
    components: {
        ForecastSection
    },
}
</script>
<style scoped>
.chart__entry, .chart__body{
    display: flex;
    flex-direction: column;
}
</style>
